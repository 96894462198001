'use strict'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTemplateSlideById, selectTemplateBook } from 'src/utils/templates'

const initialState = {
  slides: [],
  lastChoosenTemplateSlide: {},
  isChooseTemplateModalOpen: false,
  status: 'idle',
}

export const fetchTemplate = createAsyncThunk(
  'template/fetchTemplate',
  async () => {
    const response = await selectTemplateBook()
    const slides = response.book_slides.map(bookSlide => ({
      id: bookSlide.Slide.id,
      name: bookSlide.Slide.name,
      content: bookSlide.Slide.content,
      preview: bookSlide.Slide.preview,
    }))
    return slides
  },
)

export const fetchTemplateSlideById = createAsyncThunk(
  'template/fetchTemplateSlideById',
  async templateSlideId => {
    const response = await getTemplateSlideById(templateSlideId)
    return response
  },
)

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    openTemplateModal: (state, action) => {
      state.isChooseTemplateModalOpen = action.payload
    },
    clearCurrentTemplateContent: (state, action) => {
      state.lastChoosenTemplateSlide = {}
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTemplate.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.slides = action.payload || []
      })

      .addCase(fetchTemplateSlideById.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchTemplateSlideById.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { id, content, preview, slide_nubmer } = action.payload
        state.lastChoosenTemplateSlide =
          { id, content, preview, slide_nubmer } || {}
      })
  },
})

export const showBooks = state => {
  return state.book.books
}

export const {
  openTemplateModal,
  copyTemplateToSlide,
  clearCurrentTemplateContent,
} = templateSlice.actions
export default templateSlice.reducer
