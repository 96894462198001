'use strict'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  createBook,
  selectAllBooks,
  putSlide,
  deleteSlide,
  deleteBook,
  editBookTitle,
  reorderBookSlides,
} from '@/utils/editBook.js'
import { findBookById, findSlideInBookById } from './utils.js'

const initialState = {
  bookName: {
    slides: [],
    name: '',
  },
  books: [],
  slides: [],
  currentSlide: null,
  currentCanvasSlide: null,
  status: 'idle',
  needSave: false,
}

export const createBookThunk = createAsyncThunk(
  'book/createBook',
  async ({ userId, bookData }) => {
    const res = await createBook({ userId, bookData })
    const result = {
      id: res.id,
      name: res.name,
      user_id: res.user_id,
      slides:
        res.slides &&
        res.book_slides.map(slide => ({
          id: slide.Slide.id,
          name: slide.Slide.name,
          slide_number: slide.Slide.slide_number,
          preview: slide.Slide.preview,
          content: slide.Slide.content,
        })),
    }
    return result
  },
)

export const getBooks = createAsyncThunk('book/getBooks', async userId => {
  const res = await selectAllBooks(userId)
  const result = res.map(book => {
    const slides = book.book_slides.map(slide => ({
      id: slide.Slide.id,
      name: slide.Slide.name,
      slide_number: slide.Slide.slide_number,
      preview: slide.Slide.preview,
      content: slide.Slide.content,
    }))
    const sortedSlides = slides.sort((a, b) => a.slide_number - b.slide_number)
    return {
      id: book.id,
      name: book.name,
      user_id: book.user_id,
      slides: sortedSlides,
    }
  })

  return result
})

export const reorderSlidesThunk = createAsyncThunk(
  'book/reorderSlides',
  async ({ book_id, slides }) => {
    const res = await reorderBookSlides({ slides })
    return { book_id, slides: res }
  },
)

// TODO разобрать этот санк, он должен выводить id в том числе, айди используется ниже
export const putSlideThunk = createAsyncThunk(
  'book/putSlideThunk',
  async ({ book_id, slide_number, slide_content, slideId, canvasDataUrl }) => {
    const result = await putSlide({
      book_id,
      slide_number,
      slide_content,
      slideId,
      canvasDataUrl,
    })

    return {
      id: result.id,
      name: result.name || '',
      slide_number,
      preview: result.preview,
      content: result.content,
      bookId: book_id,
    }
  },
)

export const deleteSlideThunk = createAsyncThunk(
  'book/deleteSlide',
  async ({ slideId, bookId }) => {
    const result = await deleteSlide(slideId)
    if (result.error) {
      console.log('deleteSlideThunk error', result.error)
    } else return { slideId, bookId }
  },
)

export const editBookTitleThunk = createAsyncThunk(
  'book/editBookTitle',
  async ({ bookId, title }) => {
    const result = await editBookTitle(bookId, title)
    if (result.error) {
      console.log('editBookTitleThunk error', result.error)
    } else return result // TODO может нужно возвращать ответ с бэка?
  },
)

export const deleteBookThunk = createAsyncThunk(
  'book/deleteBook',
  async bookId => {
    const result = await deleteBook(bookId)
    if (result.error || result.deleted === undefined) {
      console.log('deleteSlideThunk error', result.error)
    } else return { bookId }
  },
)

export const copyTemplateToSlide = createAsyncThunk(
  'book/copyTemplateToSlide',
  async ({ templateSlideId, currentSlide, bookId }, { getState }) => {
    const state = getState()
    const contentToCopy = state.template.slides.find(
      slide => slide.id === templateSlideId,
    )
    if (contentToCopy) {
      return { slide: contentToCopy, currentSlideId: currentSlide, bookId }
    }
  },
)

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    setCurrentSlide: (state, action) => {
      state.currentSlide = action.payload
    },
    copyContentToBook: (state, action) => {
      if (!state.currentCanvasSlide) return
      const book = findBookById(state.books, action.payload.bookId)
      if (book) {
        let slide = findSlideInBookById(book, state.currentCanvasSlide.id)
        slide.content = state.currentCanvasSlide.content
      }
    },
    setNeedSave: (state, action) => {
      state.needSave = action.payload
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getBooks.pending, state => {
        state.status = 'loading'
      })
      .addCase(getBooks.fulfilled, (state, action) => {
        state.status = 'idle'
        const books = action.payload.map(book => ({
          id: book.id,
          name: book.name,
          user_id: book.user_id,
          slides: book.slides.map(slide => ({
            id: slide.id,
            slide_number: slide.slide_number,
            content: slide.content,
          })),
        }))

        const slides = action.payload.map(book => ({
          id: book.id,
          name: book.name,
          user_id: book.user_id,
          slides: book.slides.map(slide => ({
            id: slide.id,
            name: slide.name,
            slide_number: slide.slide_number,
            preview: slide.preview,
          })),
        }))
        state.books = books
        state.slides = slides
      })
      .addCase(getBooks.rejected, state => {
        state.status = 'failed'
      })
      .addCase(createBookThunk.pending, state => {
        state.status = 'loading'
      })
      .addCase(createBookThunk.fulfilled, (state, action) => {
        state.status = 'idle'
        state.bookName = action.payload
        state.books.unshift(action.payload)
      })
      .addCase(createBookThunk.rejected, state => {
        state.status = 'failed'
      })

      .addCase(deleteSlideThunk.pending, state => {
        state.status = 'loading'
      })
      .addCase(deleteSlideThunk.fulfilled, (state, action) => {
        state.status = 'idle'
        const booksSlides = findBookById(state.books, action.payload.bookId)
        const booksPreview = findBookById(state.slides, action.payload.bookId)
        if (booksSlides) {
          const slides = booksSlides.slides.filter(
            slide => slide.id !== action.payload.slideId,
          )
          const slidePreviews = booksPreview.slides.filter(
            slide => slide.id !== action.payload.slideId,
          )
          booksSlides.slides = slides
          booksPreview.slides = slidePreviews
          state.currentSlide = null
        }
      })

      .addCase(deleteBookThunk.pending, state => {
        state.status = 'loading'
      })
      .addCase(deleteBookThunk.fulfilled, (state, action) => {
        state.status = 'idle'
        // удаляем book из state.books по bookId
        state.books = state.books.filter(
          book => book.id !== action.payload.bookId,
        )
      })

      // TODO: оптимизировать. кажется в стор слайдов пишется лишнее
      // TODO: также оптимизировать хранение слайдов (статики). Сейчас хранятся превью всех версий
      // править после реализации версионирования #версионирование
      .addCase(putSlideThunk.fulfilled, (state, action) => {
        state.status = 'idle'
        const booksSlides = findBookById(state.slides, action.payload.bookId)
        const book = findBookById(state.books, action.payload.bookId)
        if (booksSlides) {
          let slidePreviews = findSlideInBookById(
            booksSlides,
            action.payload.id,
          )
          let slide = findSlideInBookById(book, action.payload.id)
          if (!slidePreviews || !slide) {
            book.slides.push(action.payload)
            booksSlides.slides.push(action.payload)
            slidePreviews = book.slides[book.slides.length - 1]
          } else {
            slidePreviews.preview = action.payload.preview
            // slide.content = action.payload.content
            state.currentCanvasSlide = {
              content: action.payload.content,
              id: action.payload.id,
            }
          }
        }
      })

      .addCase(editBookTitleThunk.pending, state => {
        state.status = 'loading'
      })
      .addCase(editBookTitleThunk.fulfilled, (state, action) => {
        state.status = 'idle'
        const book = findBookById(state.books, action.payload.id)
        book.name = action.payload.name
      })
      .addCase(reorderSlidesThunk.pending, state => {
        state.status = 'loading'
      })
      .addCase(reorderSlidesThunk.fulfilled, (state, action) => {
        state.status = 'idle'
        const book = findBookById(state.books, action.payload.book_id)
        console.log('book', book)
        const bookSlides = findBookById(state.slides, action.payload.book_id)
        console.log('bookSlides', bookSlides)
        book.slides = action.payload.slides
          .map(slide => ({
            id: slide.id,
            slide_number: slide.slide_number,
            content: slide.content,
          }))
          .sort((a, b) => a.slide_number - b.slide_number)
        bookSlides.slides = action.payload.slides
          .map(slide => ({
            id: slide.id,
            name: slide.name,
            slide_number: slide.slide_number,
            preview: slide.preview,
          }))
          .sort((a, b) => a.slide_number - b.slide_number)
      })
      .addCase(copyTemplateToSlide.pending, state => {
        state.status = 'loading'
      })
      .addCase(copyTemplateToSlide.fulfilled, (state, action) => {
        state.status = 'idle'
        const book = findBookById(state.books, Number(action.payload.bookId))
        const slide = findSlideInBookById(book, action.payload.currentSlideId)
        slide.content = action.payload.slide.content
        // TODO: не нужно в currentCanvasSlide, нужно в book.
        // const booksSlides = findBookById(state.books, action.payload.bookId)
        // пробросить айди книги
        state.currentCanvasSlide = {
          content: action.payload.slide.content,
          id: action.payload.currentSlideId,
        }
        state.needSave = true
      })
  },
})

export const showBooks = state => {
  return state.book.books
}

export const selectBookById = (state, bookId) => {
  if (!state.book?.books) {
    return null
  }
  const book = state.book.books.find(book => book.id === Number(bookId))
  return book
}

export const selectBookSlidesByBookId = (state, bookId) => {
  if (!state.book?.slides) {
    return null
  }
  const book = state.book.slides.find(book => book.id === Number(bookId))
  return book
}

export const {
  setCurrentSlide,
  setBookNameById,
  copyContentToBook,
  setNeedSave,
} = bookSlice.actions
export default bookSlice.reducer
