'use strict'
import { widgets } from '@/constants/widgets'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  icon: {},
  isDraggingOver: false,
  widgets: [],
}

export const fetchWidgetsThunk = createAsyncThunk(
  'dragWidget/fetchWidgets',
  async () => {
    const res = await fetch('/api/widgets')
    return res.json()
  },
)

export const dragWidgetSlice = createSlice({
  name: 'dragWidget',
  initialState,
  reducers: {
    setIsDraggingOver: (state, action) => {
      state.isDraggingOver = action.payload
    },
    setDragIcon: (state, action) => {
      state.icon = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWidgetsThunk.fulfilled, (state, action) => {
      state.status = 'idle'
      // удаляем book из state.books по bookId
      state.widgets = action.payload
    })
  },
})

export const { setIsDraggingOver, setDragIcon } = dragWidgetSlice.actions
export default dragWidgetSlice.reducer
