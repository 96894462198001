export const widgetCategories = [
  // {
  //   code: 'backgrounds',
  //   en_name: 'Backgrounds',
  //   ru_name: 'Фоны',
  // },
  {
    code: 'shapes',
    en_name: 'Simple shapes',
    ru_name: 'Простые фигуры',
  },
  {
    code: 'stat',
    en_name: 'Statistics',
    ru_name: 'Статистика',
  },
  {
    code: 'sweet-dreams',
    en_name: 'Sweet dreams',
    ru_name: 'Сладкие сны',
  },
  {
    code: 'emotions-lines',
    en_name: 'Emotions in lines',
    ru_name: 'Эмоции в линиях',
  },
  {
    code: 'milestones-dates',
    en_name: 'Milestones dates',
    ru_name: 'События - даты',
  },
  {
    code: 'dragons',
    en_name: 'Dragons',
    ru_name: 'Дракошки',
  },
  {
    code: 'rabbits',
    en_name: 'Rabbits',
    ru_name: 'Кролики',
  },
  {
    code: 'measures',
    en_name: 'Measures',
    ru_name: 'Измерения',
  },
  {
    code: 'decor',
    en_name: 'Decor',
    ru_name: 'Декор',
  },
  {
    code: 'other',
    en_name: 'Other',
    ru_name: 'Другие',
  },

  {
    code: 'shape-masks',
    en_name: 'Shape masks',
    ru_name: 'Маски фигур',
  },
  {
    code: 'babys-text',
    en_name: "Baby's text",
    ru_name: 'Реплики ребенка',
  },
  {
    code: 'numbers',
    en_name: 'Numbers',
    ru_name: 'Числа',
  },
  {
    code: 'frames',
    en_name: 'Frames',
    ru_name: 'Рамки',
  },
]
