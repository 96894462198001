'use strict'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentPlan: {
    planName: 'Free',
    status: 'idle',
  },
}

export const fetchUsersPlan = createAsyncThunk(
  'users/fetchUsersPlan',
  async () => {
    const response = await fetch(`/api/users_tariff`)
    return response.json()
  },
)

export const applyUserPlan = createAsyncThunk(
  'users/postUsersPlan',
  async ({ planId, plan }) => {
    console.log('==========================')
    console.log('planId', planId)
    console.log('plan', plan)
    const response = await fetch(`/api/users_tariff`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        plan: plan,
        planId: planId,
      }),
    })
    return response.json()
  },
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setPlan: (state, action) => {
      state.currentPlan.planName = action.payload.plan
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsersPlan.fulfilled, (state, action) => {
        if (action.payload.isCurrentPlan) {
          state.currentPlan.planName = action.payload.plan
        }
      })
      .addCase(applyUserPlan.rejected, (state, action) => {
        console.log('fetchUsersPlan.rejected', action.payload)
      })
      .addCase(applyUserPlan.pending, (state, action) => {
        console.log('fetchUsersPlan.pending', action.payload)
        state.currentPlan.status = 'pending'
      })
      .addCase(applyUserPlan.fulfilled, (state, action) => {
        state.currentPlan.planName = action.payload.plan
      })
  },
})

export default usersSlice.reducer
